import React, { FC, useContext } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core';
import FormBuilder from '../helpers/forms/FormBuilder';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import AutocompleteInput from '../helpers/forms/AutocompleteInput';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
    ContactEntity,
    GroupEntity,
    OfficeEntity,
} from '../../helpers/entities';
import useEntitiesAutocomplete from '../../hooks/useEntitiesAutocomplete';
import { AuthContext } from '../../stores/AuthStore';
import NameField from './Contact/NameField';
import EntityAutocomplete from '../helpers/forms/EntityAutocomplete';
import EditCloseAction from '../helpers/actions/EditCloseAction';
import { fetchCoordinates } from '../../helpers/fetchCoordinates';
import { CovveContact } from '../../helpers/processCovveResponse';
import { AutocompleteOption } from '../../crm';

interface Props {
    closeDialog(): void;
    scannedValues?: CovveContact;
}

const ContactAddDialog: FC<Props> = ({ closeDialog, scannedValues }) => {
    const navigate: NavigateFunction = useNavigate();

    const { user } = useContext(AuthContext);
    const { getAutocompleteEntities: getOffices } =
        useEntitiesAutocomplete(OfficeEntity);

    const getAllOffices: () => Promise<AutocompleteOption[]> = async () => {
        const offices: AutocompleteOption[] = await getOffices();

        return user?.isAdmin
            ? offices
            : offices.filter(office =>
                  user?.offices.find(
                      userOffice => userOffice.unique === office.value
                  )
              );
    };

    const initialValues: CovveContact = scannedValues
        ? scannedValues
        : {
              name: '',
              address: '',
              postcode: '',
              city: '',
              longitude: null,
              latitude: null,
              group_id: null,
              phone: '',
              email: '',
              web: '',
          };

    const responseHandler: (response: any) => void = response =>
        response?.id && navigate('/contact/' + response.id);

    return (
        <FormBuilder
            action={ContactEntity.apiBaseUrl}
            initialValues={initialValues}
            preSubmitFunction={fetchCoordinates}
            responseHandler={responseHandler}
            errorMessage="Fehler beim Speichern des Kontaktes!"
        >
            <DialogTitle>
                <Typography component="h2">Kontakt erstellen</Typography>
                <EditCloseAction editClose={closeDialog} edge="end" />
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="h3" gutterBottom>
                    Stammdaten
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <NameField
                            name="name"
                            label="Name"
                            required
                            autoFocus
                        />

                        <EntityAutocomplete
                            entity={GroupEntity}
                            name="group_id"
                            minInputLength={0}
                            preloadAllEntries
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field
                            component={TextField}
                            name="address"
                            label="Adresse"
                        />

                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Field
                                    component={TextField}
                                    name="postcode"
                                    label="PLZ"
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Field
                                    component={TextField}
                                    name="city"
                                    label="Ort"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3">Niederlassung</Typography>
                        <AutocompleteInput
                            getOptions={getAllOffices}
                            name="office_unique"
                            label="Niederlassung"
                            minInputLength={0}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3">Kontaktdaten</Typography>
                        <Field
                            component={TextField}
                            name="phone"
                            label="Telefon"
                        />
                        <Field
                            component={TextField}
                            name="email"
                            label="Email"
                            type="email"
                        />
                        <Field
                            component={TextField}
                            name="web"
                            label="Webseite"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button type="submit" color="primary">
                    Kontakt erstellen
                </Button>
            </DialogActions>
        </FormBuilder>
    );
};

export default ContactAddDialog;

import React, { FC, PropsWithChildren, useContext } from 'react';
import { AuthContext } from '../../stores/AuthStore';
import { Navigate } from 'react-router-dom';

interface Props {
    permission: string;
}

const RouteRestricted: FC<PropsWithChildren<Props>> = ({
    permission,
    children,
}) => {
    const { userCan } = useContext<AuthContext>(AuthContext);

    if (!userCan(permission)) {
        console.error('Permission denied! Redirecting...');
        return <Navigate to="/" />;
    }

    return children;
};

export default RouteRestricted;

import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import mapUser, { User } from '../mappers/userMapper';
import Ajax from '../helpers/Ajax';
import Cookies from 'js-cookie';
import { useAsync } from 'react-use';
import logError from '../errors/logError';
import { isErrorInstance } from '../errors/error-helpers';
import HttpAuthenticationError from '../errors/HttpAuthenticationError';
import * as Sentry from '@sentry/browser';

export const AUTH_COOKIE_NAME = 'crm-token';

export enum AppState {
    loading = 'loading',
    authenticated = 'authenticated',
    unauthenticated = 'unauthenticated',
}

export interface AuthContext {
    state: AppState;
    user: User | null;
    userCan(permission: string): boolean;
    login: { (apiToken: string): void };
    logout: { (): Promise<boolean> };
    reloadUser: { (): Promise<void> };
}
export const AuthContext = React.createContext<AuthContext>({} as AuthContext);

interface Props {
    children: any;
}

const AuthStore = ({ children }: Props): ReactElement => {
    const [user, setUser] = useState<User | null>(null);

    const resetUser = (): void => {
        Cookies.remove(AUTH_COOKIE_NAME);
        setUser({} as User);
    };

    useEffect(() => {
        if (user?.id === undefined) {
            return;
        }
        const interval = setInterval(async () => {
            try {
                await Ajax.get('me');
            } catch (error) {
                clearInterval(interval);
                resetUser();
            }
        }, 60000); // 1 min

        return () => clearInterval(interval);
    }, [user]);

    const fetchCurrentUser = async () => {
        try {
            const response = await Ajax.get('me');
            const currentUser = mapUser(response);
            setUser(currentUser);
            Sentry.setUser({
                id: currentUser.id,
            });
        } catch (e) {
            setUser({} as User);
            Sentry.setUser(null);
            if (!isErrorInstance(e, HttpAuthenticationError)) {
                logError(e);
            }
        }
    };

    useAsync(fetchCurrentUser, []);

    const isDevMode =
        !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

    const login = async (apiToken: string) => {
        Cookies.set(AUTH_COOKIE_NAME, apiToken, {
            path: '/',
            sameSite: 'strict',
            secure: !isDevMode,
        });
        await fetchCurrentUser();
    };

    const logout = async (): Promise<boolean> => {
        try {
            Sentry.setUser(null);
            await Ajax.get('logout');
            resetUser();
            return true;
        } catch (e) {
            logError(e);
            return false;
        }
    };

    const state: AppState = useMemo(() => {
        switch (true) {
            case user === null:
                return AppState.loading;
            case user?.id !== undefined:
                return AppState.authenticated;
            default:
                return AppState.unauthenticated;
        }
    }, [user]);

    const userCan = (permission: string): boolean =>
        user?.isAdmin || !!user?.permissions.includes(permission);

    return (
        <AuthContext.Provider
            value={{
                state,
                user,
                userCan,
                login,
                logout,
                reloadUser: fetchCurrentUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthStore;

import { purple, teal } from '@material-ui/core/colors';
import webportalTheme from '../../../../../resources/assets/react/theme';
import { createTheme, Theme, ThemeOptions } from '@material-ui/core';

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        yellow: Palette['primary'];
    }
    interface PaletteOptions {
        yellow: PaletteOptions['primary'];
    }
}

const defaultTheme: Theme = createTheme();

// A custom theme for this app
const theme: ThemeOptions = createTheme({
    palette: {
        primary: {
            main: teal['500'],
            contrastText: '#FFF',
        },
        secondary: {
            main: purple['900'],
        },
        background: {
            default: '#f5f5f5',
        },
        yellow: webportalTheme.palette.secondary,
    },

    typography: {
        h1: {
            fontSize: '1.5rem',
            fontWeight: 400,
            paddingTop: '8px',
            paddingBottom: '8px',
        },
        h2: {
            fontSize: '1.2rem',
        },
        h3: {
            fontSize: '1rem',
        },
        h4: {
            fontSize: '0.9rem',
        },
        h5: {
            fontSize: '0.8rem',
        },
        h6: {
            fontSize: '0.75rem',
        },
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
        },
        MuiToolbar: {
            gutters: {
                [defaultTheme.breakpoints.up('sm')]: {
                    paddingLeft: '16px',
                    paddingRight: '16px',
                },
            },
        },
    },
    /*config: {
        menuWidth: 240,
    },*/
    props: {
        MuiTextField: {
            variant: 'outlined',
            margin: 'normal',
            fullWidth: true,
        },
        MuiDialogTitle: {
            disableTypography: true,
        },
        MuiFormControl: {
            variant: 'outlined',
            fullWidth: true,
        },
        MuiLink: {
            color: 'secondary',
            style: {
                cursor: 'pointer',
            },
        },
        MuiIconButton: {
            color: 'secondary',
        },
        MuiBackdrop: {
            style: {
                color: '#FFF',
            },
        },
    },
});

export type CrmTheme = Theme;

export default theme;

import HttpNotFoundError from '../errors/HttpNotFoundError';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { isErrorInstance } from '../errors/error-helpers';

const useError: () => {
    redirectIfNotFoundException: (error: unknown) => boolean;
} = () => {
    const navigate: NavigateFunction = useNavigate();

    const redirectIfNotFoundException: (error: unknown) => boolean = error => {
        if (isErrorInstance(error, HttpNotFoundError)) {
            navigate('/404', { replace: true });
            return true;
        }
        return false;
    };

    return { redirectIfNotFoundException };
};

export default useError;

import React, { FC } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Close } from '@material-ui/icons';
import FormBuilder from '../helpers/forms/FormBuilder';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { userRoles } from '../../mappers/userMapper';
import PasswordField from '../helpers/forms/PasswordField';
import { Theme } from '@mui/material';

interface Props {
    closeDialog: { (): void };
}

const UserAddDialog: FC<Props> = ({ closeDialog }) => {
    const navigate: NavigateFunction = useNavigate();
    const theme: Theme = useTheme();
    const smallScreen: boolean = useMediaQuery(theme.breakpoints.down('sm'));

    const responseHandler: (response: any) => void = response =>
        response?.id && navigate('/user/' + response.id);

    return (
        <FormBuilder
            action="users"
            initialValues={{
                last_name: '',
                first_name: '',
                email: '',
                password: '',
                role: 'user',
            }}
            responseHandler={responseHandler}
            errorMessage="Fehler beim Speichern des Benutzers!"
        >
            <DialogTitle>
                <Typography component="h2">Benutzer hinzufügen</Typography>
                <IconButton onClick={closeDialog} size="small" edge="end">
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Field
                    component={TextField}
                    name="role"
                    label="Rolle"
                    required
                    autoFocus
                    select
                    fullWidth
                >
                    {Object.entries(userRoles).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                            {value}
                        </MenuItem>
                    ))}
                </Field>
                <Grid container spacing={smallScreen ? 0 : 2}>
                    <Grid item xs={12} md={6}>
                        <Field
                            component={TextField}
                            name="first_name"
                            label="Vorname"
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field
                            component={TextField}
                            name="last_name"
                            label="Nachname"
                            required
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Field
                    component={TextField}
                    name="email"
                    label="E-Mail"
                    type="email"
                    required
                    fullWidth
                />
                <PasswordField name="password" label="Passwort" required />
                <Typography gutterBottom color="textSecondary">
                    Dem neuen Benutzer wird automatisch eine Mail mit seinem
                    Passwort zugeschickt.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button type="submit" color="primary">
                    Benutzer hinzufügen
                </Button>
            </DialogActions>
        </FormBuilder>
    );
};

export default UserAddDialog;

import React, { FC, RefObject, useRef, useState } from 'react';
import {
    Box,
    createStyles,
    DialogContent,
    DialogContentText,
    IconButton,
    LinearProgress,
    Menu,
    MenuItem,
    Paper,
    useMediaQuery,
} from '@material-ui/core';
import Webcam from 'react-webcam';
import 'react-image-crop/dist/ReactCrop.css';
import { Crop } from 'react-image-crop';
import {
    CovveApiResponse,
    CovveContact,
    CovveContactPerson,
    processCovveResponse,
} from '../../../helpers/processCovveResponse';
import logError from '../../../errors/logError';
import Ajax from '../../../helpers/Ajax';
import { useSnackbar } from 'notistack';
import { useAsync, useToggle } from 'react-use';
import { Lens, SwitchCamera, ZoomIn, ZoomOut } from '@material-ui/icons';
import useMenu from '../../../hooks/useMenu';
import { makeStyles } from '@material-ui/core/styles';
import { CrmTheme } from '../../../theme';
import { cropImage } from '../../../helpers/cropImage';
import WebcamCrop from '../../WebcamCrop';
import BusinessCardScanDialogActions from './BusinessCardScanDialogActions';

const videoConstraints: MediaTrackConstraints = {
    width: 1920,
    height: 1080,
    aspectRatio: 1920 / 1080,
    facingMode: 'environment',
};

const initCrop: Crop = {
    unit: '%',
    width: 50,
    height: 50,
    x: 25,
    y: 25,
};

const initCropZoom: Crop = {
    unit: '%',
    width: 75,
    height: 75,
    x: 12.5,
    y: 12.5,
};

interface Props {
    setIsScanned(isScanned: boolean): void;
    setScannedValues(scannedValues: CovveContact | CovveContactPerson): void;
    contactId?: string;
}

const BusinessCardScanContent: FC<Props> = ({
    setIsScanned,
    setScannedValues,
    contactId,
}) => {
    const classes = useStyles();
    const onlySm: boolean = useMediaQuery((theme: CrmTheme) =>
        theme.breakpoints.only('sm')
    );

    const { isMenuOpen, handleMenuOpen, handleMenuClose } = useMenu();
    const { enqueueSnackbar } = useSnackbar();

    const webcamRef: RefObject<Webcam> = useRef<Webcam>(null);
    const menuRef: RefObject<HTMLButtonElement> =
        useRef<HTMLButtonElement>(null);

    const [image, setImage] = useState<HTMLCanvasElement | null>(null);
    const [isLoading, setLoading] = useState(false);
    const [crop, setCrop] = useState<Partial<Crop>>(initCropZoom);
    const [deviceId, setDeviceId] = useState<string | null>(null);
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const [zoom, toggleZoom] = useToggle(true);

    useAsync(async () => {
        const mediaDevices: MediaDeviceInfo[] =
            await navigator.mediaDevices.enumerateDevices();
        setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput'));
    }, []);

    const onClickCrop: () => void = () => {
        setImage(
            cropImage(
                webcamRef.current,
                videoConstraints,
                crop as Partial<Crop>
            )
        );
        setCrop({});
    };

    const changeZoom: () => void = () => {
        if (!zoom) {
            setCrop(initCropZoom);
            toggleZoom();
            return;
        }
        setCrop(initCrop);
        toggleZoom();
    };

    const submitScan: () => Promise<void> = async () => {
        if (!image) {
            enqueueSnackbar('Keine Bild-Daten gefunden!', {
                variant: 'error',
            });
            repeatScan();
            return;
        }

        setLoading(true);

        submitImage(image);
    };

    const submitImage: (image: HTMLCanvasElement) => void = image => {
        image.toBlob(async blob => {
            try {
                if (!blob) {
                    enqueueSnackbar('Keine Bild-Daten gefunden!', {
                        variant: 'error',
                    });
                    repeatScan();
                }
                const response: CovveApiResponse =
                    await Ajax.post<CovveApiResponse>(
                        'business_card',
                        { image: blob },
                        'formData'
                    );

                setScannedValues(
                    !contactId
                        ? await processCovveResponse(response)
                        : await processCovveResponse(response, contactId)
                );

                setLoading(false);

                setIsScanned(true);
            } catch (error) {
                logError(error);
                enqueueSnackbar(
                    'Fehler beim Verarbeiten des Scans, bitte versuchen Sie es erneut!',
                    {
                        variant: 'error',
                    }
                );
                repeatScan();
            }
        });
    };

    const repeatScan: () => void = (): void => {
        setLoading(false);
        setIsScanned(false);
        setImage(null);
        setCrop(zoom ? initCropZoom : initCrop);
    };

    const handleMenuClick: (deviceId: string) => () => void =
        deviceId => () => {
            setDeviceId(deviceId);
            handleMenuClose();
        };

    return (
        <Paper>
            <>
                <DialogContent dividers>
                    <Box
                        position="relative"
                        maxWidth={`calc(80vh * ${videoConstraints.aspectRatio})`}
                        marginX="auto"
                    >
                        <WebcamCrop
                            onChange={(newCrop, newPercentCrop) =>
                                setCrop(newPercentCrop)
                            }
                            image={image}
                            webCamRef={webcamRef}
                            videoConstraints={videoConstraints}
                            deviceId={deviceId}
                            crop={crop as Crop}
                        />
                        {devices.length > 1 && image === null && (
                            <>
                                <IconButton
                                    size="small"
                                    ref={menuRef}
                                    onClick={handleMenuOpen}
                                    className={classes.switchCameraButton}
                                >
                                    <SwitchCamera />
                                </IconButton>
                                <Menu
                                    anchorEl={menuRef.current}
                                    open={isMenuOpen}
                                    onClose={handleMenuClose}
                                    transformOrigin={{
                                        horizontal: 'right',
                                        vertical: 'top',
                                    }}
                                >
                                    {devices.map(device => (
                                        <MenuItem
                                            key={device.deviceId}
                                            onClick={handleMenuClick(
                                                device.deviceId
                                            )}
                                        >
                                            {device.label}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>
                        )}
                        {!image && (
                            <IconButton
                                size="small"
                                onClick={changeZoom}
                                className={classes.switchCropZoom}
                            >
                                {!zoom ? <ZoomIn /> : <ZoomOut />}
                            </IconButton>
                        )}
                        {!image && onlySm && (
                            <IconButton
                                onClick={onClickCrop}
                                className={classes.lensButton}
                            >
                                <Lens />
                            </IconButton>
                        )}
                    </Box>
                    {(image || !isLoading) && (
                        <DialogContentText variant="h5">
                            Um ein gutes Ergebnis zu erzielen, kontrollieren Sie
                            bitte, ob die Schrift auf dem Bild gut lesbar ist.
                            Ist dies nicht der Fall, wiederholen Sie den Scan.
                        </DialogContentText>
                    )}
                </DialogContent>
                {isLoading && <LinearProgress />}
                <BusinessCardScanDialogActions
                    image={image}
                    reScan={repeatScan}
                    isLoading={isLoading}
                    submitScan={submitScan}
                    scan={onClickCrop}
                />
            </>
        </Paper>
    );
};

export default BusinessCardScanContent;

const useStyles = makeStyles((theme: CrmTheme) =>
    createStyles({
        switchCameraButton: {
            position: 'absolute',
            top: theme.spacing(2),
            right: theme.spacing(2),
            backgroundColor: theme.palette.background.paper,
            opacity: 0.5,
            '&:hover': {
                backgroundColor: theme.palette.background.paper,
                opacity: 0.8,
            },
            [theme.breakpoints.down('xs')]: {
                top: theme.spacing(0.5),
                right: theme.spacing(0.5),
            },
        },
        switchCropZoom: {
            position: 'absolute',
            top: theme.spacing(2),
            left: theme.spacing(2),
            backgroundColor: theme.palette.background.paper,
            opacity: 0.5,
            '&:hover': {
                backgroundColor: theme.palette.background.paper,
                opacity: 0.8,
            },
            [theme.breakpoints.down('xs')]: {
                top: theme.spacing(0.5),
                left: theme.spacing(0.5),
            },
        },
        lensButton: {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
            opacity: 0.5,
            '&:hover': {
                backgroundColor: theme.palette.background.paper,
                opacity: 0.8,
            },
        },
    })
);

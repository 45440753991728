import React, { FC } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';

interface Props {
    date: Dayjs | null;
    label: string;
    id: string;
    clearable?: boolean;
    changeDateValue: (value: Dayjs | null, fieldName: string) => void;
    required?: boolean;
    maxDate?: Dayjs;
}

const DateField: FC<Props> = ({
    date,
    label,
    id,
    clearable = false,
    changeDateValue,
    required = false,
    maxDate,
}) => {
    const handleDateChange: (date: Dayjs | null) => void = date => {
        changeDateValue(date, id);
    };

    const handleClearDate: (event: React.MouseEvent) => void = event => {
        event.stopPropagation();
        handleDateChange(null);
        changeDateValue(null, id);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
                sx={{ width: '100%' }}
                label={label}
                value={date ? dayjs(date, 'DD.MM.YYYY') : null}
                maxDate={maxDate}
                onChange={handleDateChange}
                slotProps={{
                    field: {
                        clearable: !clearable,
                        onClear: () => handleClearDate,
                    },
                    textField: {
                        required,
                    },
                }}
            />
        </LocalizationProvider>
    );
};

export default DateField;

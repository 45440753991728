import React, { FC, useContext } from 'react';
import FormBuilder from '../../helpers/forms/FormBuilder';
import CrmToolbar from '../../helpers/CrmToolbar';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { IconButton, Typography } from '@material-ui/core';
import { Delete, Edit, Save } from '@material-ui/icons';
import useEditable from '../../../hooks/useEditable';
import { RivalEntity } from '../../../helpers/entities';
import { AuthContext } from '../../../stores/AuthStore';
import DeleteAction from '../../helpers/actions/DeleteAction';
import { useSnackbar } from 'notistack';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import EditCloseAction from '../../helpers/actions/EditCloseAction';
import { Rival } from '../../../mappers/rivalMapper';

interface Props {
    rival: Rival;
    reloadRival(): void;
}

const Name: FC<Props> = ({ rival, reloadRival }) => {
    const { isEdited, editOpen, editClose } = useEditable();
    const { userCan } = useContext<AuthContext>(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const navigate: NavigateFunction = useNavigate();

    const editResponseHandler: () => void = () => {
        editClose();
        reloadRival();
    };

    const onDeleteRival: () => void = () => {
        enqueueSnackbar(`${rival.name} gelöscht`, {
            variant: 'success',
        });
        navigate('/', { replace: true });
    };

    if (!rival) {
        return null;
    }

    if (isEdited) {
        return (
            <FormBuilder
                action={RivalEntity.apiBaseUrl + '/' + rival.id}
                initialValues={{
                    name: rival.name,
                }}
                responseHandler={editResponseHandler}
                errorMessage="Fehler beim Speichern des Namens!"
            >
                <CrmToolbar
                    title={
                        <Field
                            component={TextField}
                            size="small"
                            name="name"
                            label="Name"
                            fullWidth
                            required
                            autoFocus
                        />
                    }
                    disableTypography
                    buttons={
                        <>
                            <IconButton type="submit">
                                <Save />
                            </IconButton>
                            <EditCloseAction
                                size="medium"
                                editClose={editClose}
                            />
                        </>
                    }
                />
            </FormBuilder>
        );
    }

    return (
        <CrmToolbar
            title={rival.name}
            buttons={
                <>
                    {userCan('delete') && (
                        <DeleteAction
                            label="Wettbewerber löschen"
                            action={RivalEntity.apiBaseUrl + '/' + rival.id}
                            onDeleteHandler={onDeleteRival}
                            icon={Delete}
                        >
                            <>
                                <Typography gutterBottom>
                                    Sind Sie sicher, dass Sie den Wettbewerber{' '}
                                    <strong>{rival.name}</strong> unwiderruflich
                                    und vollständig löschen wollen?
                                </Typography>
                                <Typography>
                                    Davon betroffen sind auch:
                                    <ul>
                                        <li>Anschrift</li>
                                        <li>Kontaktdaten</li>
                                        <li>Notizen</li>
                                        <li>Alle Dateien</li>
                                    </ul>
                                </Typography>
                            </>
                        </DeleteAction>
                    )}
                    <IconButton edge="end" onClick={editOpen}>
                        <Edit />
                    </IconButton>
                </>
            }
        />
    );
};

export default Name;

import React, { FC, ReactElement } from 'react';
import { FallbackProps } from 'react-error-boundary';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Typography,
} from '@material-ui/core';

const ErrorHandling: FC<FallbackProps> = ({
    error,
    resetErrorBoundary,
}: FallbackProps): ReactElement => (
    <Container maxWidth="xs">
        <Box mt={4}>
            <Card>
                <CardHeader
                    titleTypographyProps={{ variant: 'h1' }}
                    title="Fehler aufgetreten"
                />
                <CardContent>
                    <Typography>
                        Leider ist ein Fehler aufgetreten. Wir wurden darüber
                        bereits informiert.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button color="secondary" onClick={resetErrorBoundary}>
                        CRM neu laden
                    </Button>
                </CardActions>
            </Card>
        </Box>
    </Container>
);

export default ErrorHandling;

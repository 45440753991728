import React, { ReactElement } from 'react';
import CrmCard from './helpers/CrmCard';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Link, Route } from 'react-router-dom';
import DataSetEditor from './modules/DataSet/DataSetEditor';

export interface DataSet {
    plural: string;
    singular: string;
    slug: string;
    attributes: string[];
}

export const dataSets: DataSet[] = [
    {
        plural: 'Gruppen',
        singular: 'Gruppe',
        slug: 'groups',
        attributes: ['group'],
    },
    {
        plural: 'Kooperationen',
        singular: 'Kooperation',
        slug: 'cooperations',
        attributes: ['cooperation'],
    },
    {
        plural: 'Kontakttypen',
        singular: 'Kontakttyp',
        slug: 'contact_types',
        attributes: ['contact_type'],
    },
    {
        plural: 'Werkstattsysteme',
        singular: 'Werkstattsystem',
        slug: 'workshop_systems',
        attributes: ['workshop_system'],
    },
    {
        plural: 'Herstellermarken',
        singular: 'Herstellermarke',
        slug: 'brands',
        attributes: ['brand'],
    },
    {
        plural: 'Gutachter',
        singular: 'Gutachter',
        slug: 'accident_consultants',
        attributes: ['accident_consultant'],
    },
    {
        plural: 'Wettbewerber',
        singular: 'Wettbewerber',
        slug: 'competitors',
        attributes: ['competitor'],
    },
    {
        plural: 'Positionen',
        singular: 'Position',
        slug: 'positions',
        attributes: ['position_d', 'position_m', 'position_f'],
    },
    {
        plural: 'Kampagnen',
        singular: 'Kampagne',
        slug: 'campaigns',
        attributes: ['campaign'],
    },
];

export const baseUrl = '/settings/data-sets/';

const DataSetOverView = (): ReactElement => (
    <>
        <Route path={`${baseUrl}:slug?/:id?`} element={<DataSetEditor />} />
        <Route path={baseUrl}>
            <Box maxWidth="300px" mx="auto">
                <CrmCard title="Stammdatenverwaltung">
                    <List component="nav" disablePadding>
                        {dataSets.map((dataSet: DataSet) => (
                            <ListItem
                                button
                                disableGutters
                                key={dataSet.slug}
                                component={Link}
                                to={`${baseUrl}${dataSet.slug}`}
                            >
                                <ListItemIcon>
                                    <ChevronRight />
                                </ListItemIcon>
                                <ListItemText primary={dataSet.plural} />
                            </ListItem>
                        ))}
                    </List>
                </CrmCard>
            </Box>
        </Route>
    </>
);

export default DataSetOverView;

import Webcam from 'react-webcam';
import React, { FC, RefObject } from 'react';
import { useSnackbar } from 'notistack';
import ReactCrop, { Crop } from 'react-image-crop';
import logError from '../errors/logError';

interface Props {
    onChange: (newCrop: Crop, newPercentCrop: Crop) => void;
    image: HTMLCanvasElement | null;
    webCamRef: RefObject<Webcam>;
    videoConstraints: MediaTrackConstraints;
    deviceId: string | null;
    crop: Crop;
}

const WebcamCrop: FC<Props> = ({
    onChange,
    image,
    webCamRef,
    videoConstraints,
    deviceId,
    crop,
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const handleUserMediaError: (
        error: string | DOMException
    ) => void = error => {
        logError(error);
        enqueueSnackbar('Es konnte auf keine Webcam zugegriffen werden!', {
            variant: 'error',
        });
    };

    return (
        <ReactCrop
            onChange={onChange}
            crop={crop}
            style={{
                top: '50%',
                left: '50%',
                width: '100%',
                transform: 'translate(-50%)',
            }}
            disabled
        >
            {image === null ? (
                <Webcam
                    audio={false}
                    ref={webCamRef}
                    screenshotFormat="image/png"
                    videoConstraints={{
                        ...videoConstraints,
                        deviceId: deviceId ?? undefined,
                    }}
                    width="100%"
                    style={{
                        display: 'block',
                        objectFit: 'cover',
                        aspectRatio: videoConstraints.aspectRatio?.toString(),
                    }}
                    onUserMediaError={handleUserMediaError}
                />
            ) : (
                <img src={image.toDataURL()} alt=""></img>
            )}
        </ReactCrop>
    );
};

export default WebcamCrop;

import { mapUsers, User } from './userMapper';
import mapContact, { Contact } from './contactMapper';
import { ContactPerson } from './contactPersonMapper';
import dayjs, { Dayjs } from 'dayjs';
import { mapReportCopies, ReportCopy } from './reportCopyMapper';
import { Competitor, mapCompetitors } from './competitorMapper';

export type ReportType = 'call' | 'visit' | 'written' | 'virtual';

export const reportTypes: { [key in ReportType]: string } = <const>{
    call: 'Telefonat',
    virtual: 'Virtuell',
    visit: 'Besuch',
    written: 'Schriftlich',
};

export interface Report {
    id: string;
    contact_id: string;
    user_id: string;
    user?: User;
    contact?: Contact;
    type: ReportType;
    report: string;
    report_excerpt: string;
    date: Dayjs;
    date_formatted: string;
    notification_date: Dayjs | null;
    notification_date_formatted: string | null;
    overdue: boolean;
    done: Dayjs | null;
    done_formatted: string | null;
    contact_persons: ContactPerson[];
    internal_participants: User[];
    subject: string;
    copies: ReportCopy[];
    competitors: Competitor[];
}

const mapReport = (input: any): Report => ({
    ...input,
    date: dayjs(input.date),
    notification_date: input.notification_date
        ? dayjs(input.notification_date)
        : null,
    done: input.done ? dayjs(input.done) : null,
    date_formatted: dayjs(input.date).format('DD.MM.YYYY'),
    notification_date_formatted: input.notification_date
        ? dayjs(input.notification_date).format('DD.MM.YYYY')
        : null,
    overdue:
        input.notification_date !== null &&
        !input.done &&
        dayjs(input.notification_date) <= dayjs(),
    done_formatted: input.done ? dayjs(input.done).format('DD.MM.YYYY') : null,
    report_excerpt: (input.report.length <= 170
        ? input.report.replace('\n', ' ')
        : input.report.substring(0, 170) + '...'
    ).replace(/[\r\n]+/g, ' '),
    internal_participants: input.internal_participants
        ? mapUsers(input.internal_participants)
        : [],
    contact: input.contact ? mapContact(input.contact) : [],
    copies: input.copies ? mapReportCopies(input.copies) : [],
    competitors: input.competitors ? mapCompetitors(input.competitors) : [],
});

export const mapReports = (input: any): Report[] => input.map(mapReport);

export default mapReport;

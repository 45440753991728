import React, { ReactElement, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import CrmCard from '../../helpers/CrmCard';
import { lighten, LinearProgress, useTheme } from '@material-ui/core';
import { CrmTheme } from '../../../theme';
import Ajax from '../../../helpers/Ajax';
import { useDebounce } from 'react-use';
import dayjs from 'dayjs';

interface EntityReports {
    ek: number[];
    bk: number[];
    combined: number[];
    labels: string[];
}

interface Props {
    apiUrl: string;
    title: string;
    startDate: Date;
    endDate: Date;
}

const EntityReports = ({
    apiUrl,
    title,
    startDate,
    endDate,
}: Props): ReactElement => {
    const theme: CrmTheme = useTheme();
    const [entityReports, setEntityReports] = useState<EntityReports | null>(
        null
    );

    const getEntityReports = async (): Promise<void> => {
        const reports = await Ajax.get<Obj>(apiUrl, {
            startDate: dayjs(startDate).format('YYYY-MM-DD'),
            endDate: dayjs(endDate).format('YYYY-MM-DD'),
        });

        const entityReportsTmp: EntityReports = {
            ek: [],
            bk: [],
            combined: [],
            labels: [],
        };

        Object.keys(reports)
            .sort((a, b) =>
                reports[a].EK + reports[a].BK > reports[b].EK + reports[b].BK
                    ? -1
                    : 1
            )
            .map(reportLabel => {
                entityReportsTmp.labels.push(reportLabel);
                entityReportsTmp.ek.push(reports[reportLabel].EK);
                entityReportsTmp.bk.push(reports[reportLabel].BK);
                entityReportsTmp.combined.push(
                    reports[reportLabel].EK + reports[reportLabel].BK
                );
            });

        setEntityReports(entityReportsTmp);
    };

    useDebounce(getEntityReports, 500, [startDate, endDate, apiUrl]);

    const statisticsData = {
        labels: entityReports?.labels,
        datasets: [
            {
                label: 'Erstkontakte',
                backgroundColor: theme.palette.primary.main,
                data: entityReports?.ek,
            },
            {
                label: 'Bestandskontakte',
                backgroundColor: lighten(theme.palette.primary.main, 0.7),
                data: entityReports?.bk,
            },
            {
                label: 'Kombiniert',
                backgroundColor: theme.palette.secondary.main,
                data: entityReports?.combined,
            },
        ],
    };

    const maxReports = Math.max(...(entityReports?.combined || []), 10);

    return (
        <CrmCard title={title}>
            {entityReports ? (
                <Bar
                    data={statisticsData}
                    options={{
                        scales: {
                            y: {
                                ticks: {
                                    stepSize: maxReports >= 20 ? 10 : 2,
                                    maxTicksLimit:
                                        Math.ceil(maxReports / 10) * 10,
                                },
                            },
                            x: {
                                ticks: {
                                    minRotation: 45,
                                },
                            },
                        },
                    }}
                />
            ) : (
                <LinearProgress />
            )}
        </CrmCard>
    );
};

export default EntityReports;
